.panel {
	@apply border rounded-t-md my-4 dark:bg-dark-700 dark:border-dark-400;

	&__header {
		@apply bg-s-gray-100 flex justify-between items-center px-4 py-5 text-dark-200 dark:bg-dark-600 dark:text-dark-100 dark:border-gray-400;
	}

	&__title {
		@apply flex justify-start items-center gap-x-2 text-md text-dark-200
		 dark:text-gray-400 font-medium;
	}

	&__button {
		@apply outline outline-1 outline-secondary px-2 py-1 text-sm;
	}

	&__body {
		@apply transition-all duration-300 ease-in-out;
	}
}
