.alert-box {
  @apply flex items-center justify-between gap-x-5 px-4 py-1.5 rounded-md;

  &__content {
    @apply flex gap-x-2 items-center justify-start text-xs font-normal;
  }

  &--info {
    @apply text-info bg-info/10;
  }

  &--danger {
    @apply text-danger bg-danger-l;
  }



  &--warning {
    @apply bg-warning-100 text-warning-600 border-warning-200 border;
  }
}