@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.salla.network/fonts/pingarlt.css?v=0.2');
@import url('https://cdn.salla.network/fonts/sallaicons.css');

@layer base {
  html {
    font-family: "PingARLT", Arial, Helvetica, sans-serif;
  }
  body {
    font-size: 16px;
  }
}

::-webkit-scrollbar {
    display: none;
  }

html,body{
  height: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

.tooltip-arrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #b2f5ea;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}
.handle {
  touch-action: none;
}

.tab{
  @apply  w-1/2 border border-secondary-50 font-medium text-dark-300 py-2 ;
}
.active-tab{
    @apply bg-secondary-50 text-primary-d;
}
.swal-title{
  @apply text-2xl font-bold;
}
.swal-html-container{
  @apply text-xl font-normal;
}