.modal {
	@apply relative z-50;

	&__backdrop {
		@apply fixed inset-0 bg-black/30;
	}

	&__body {
		@apply relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-screen sm:max-w-3xl dark:bg-dark-700 max-h-screen;
	}

	&__container {
		@apply fixed inset-0 flex w-screen items-center justify-center p-4 overflow-y-scroll ;
	}

	&__header {
		@apply bg-secondary-50 flex justify-between items-center px-5 py-4 dark:bg-dark-600 
		rounded-tr-lg rounded-tl-lg text-primary-l;
	}

	&__footer {
		@apply flex justify-between items-center bg-gray-50 py-3 px-4 dark:bg-dark-600 rounded-br-lg rounded-bl-lg;
	}
}
