.tree-select{
  @apply w-full text-right;
  div.dropdown{
    @apply w-full text-right;
    a.dropdown-trigger{
      @apply w-full flex items-center justify-between px-2 py-2 border border-gray-200 dark:border-dark-300 rounded-md shadow-sm cursor-pointer;
      &::after{
        @apply text-dark-100 text-xs #{!important};
      }
      .tag-list{
        @apply w-full ;
        .tag-item{
          @apply mx-0;
          .placeholder{
            @apply text-xs;
          }
        }
        .tag{
          @apply inline-flex items-center justify-center px-2 py-1 mx-0.5 text-xs font-bold leading-none text-primary-l bg-secondary-25 rounded-full;

          .tag-remove{
            @apply text-danger ring-danger ms-3 text-xs;
          }
        }
      }
      span{

      }
    }
    .dropdown-content{
      @apply w-full z-50;
      .search{
        @apply w-full px-4 py-2 border-b border-secondary-50 focus:outline-none focus:ring-1 focus:ring-secondary-50 focus:border-transparent;
        input{
          @apply w-full;
        }
      }
      li label span{
        @apply text-dark-200 text-xs ms-2;
      }
      li .checkbox-item{
        @apply text-primary-l ring-primary-l;
      }
      .toggle{
        @apply text-base px-1;
      }
    }
  }
}
